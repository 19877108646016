<template>
  <b-container class="bg-white py-4">
    <b-row v-if="!permission">
      <b-col>
        <b-card>
          <h4>You do not have access permission for this page</h4>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="permission">
      <b-col>
        <b-container>
          <b-row class="mb-4" v-if="firststep">
            <b-col>
              <h4 class="mb-4">Adding a new piece of news</h4>

              <div class="mb-2"><strong>Publisher (source)</strong></div>
              <b-form-input id="publisherInput" v-model="item.publisher" placeholder="Bloomberg" required class="mb-4"> </b-form-input>

              <div class="mb-2"><strong>Headline</strong></div>
              <b-form-input id="nameInput" v-model="item.name" placeholder="News headline" required class="mb-4"> </b-form-input>

              <div class="mb-2"><strong>Introduction</strong></div>
              <b-form-textarea id="bodyInput" v-model="item.body" placeholder="Introduction..." required rows="4" class="mb-4"> </b-form-textarea>

              <div class="mb-2"><strong>Link</strong></div>
              <b-form-input id="linkInput" v-model="item.link" placeholder="https://www.example.com" required class="mb-4"> </b-form-input>

              <div class="mb-2"><strong>Date</strong></div>
              <b-form-input id="dateInput" type="date" v-model="item.date" class="mb-4"> </b-form-input>

              <div class="mb-2"><strong>Country</strong></div>
              <b-form-select id="countryInput" v-model="item.countryCode" :options="countryOptions" class="mb-4"></b-form-select>

              <b-button v-if="validate" class="mr-2" variant="primary" size="sm" v-on:click="preview">Preview</b-button>
              <b-button variant="outline-primary" size="sm" :to="{ name: 'Start' }">Cancel</b-button>
            </b-col>
          </b-row>

          <b-row class="mb-4" v-if="previewstep || finalstep">
            <b-col>
              <div class="mb-2">
                <strong>{{ item.name }}</strong>
              </div>
              <div class="mb-2">{{ item.date }} - {{ item.publisher }} - {{ item.countryCode }} - {{ item.countryName }}</div>
              <div class="mb-2">{{ item.source }} - {{ item.langCode }} - {{ item.langName }}</div>
              <div class="mb-2">{{ item.body }}</div>
              <div class="mb-2"><a :href="item.link" target="_blank">{{ item.link }}</a></div>
              <div class="mb-2">{{ item.id }}</div>
              <span v-if="!saving && !finalstep">
                <b-button v-if="validate" class="mr-2" variant="primary" size="sm" v-on:click="save">Save</b-button>
                <b-button variant="outline-primary" size="sm" v-on:click="edit">Edit</b-button>
              </span>
              <span v-if="saving"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
              <span v-if="finalstep">
                <b-button class="mr-2" variant="outline-primary" size="sm" :to="{ name: 'Start' }">Back to Start</b-button>
                <b-button variant="danger" size="sm" v-on:click="remove">Delete</b-button>
              </span>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

import ac from '../libs/accesscontrol'
import countries from '../libs/countries'

export default {
  name: 'PublicationAdd',
  components: {},
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validate: function () {
      return this.item.name && this.item.body && this.item.link && this.item.publisher && this.item.countryCode
    }
  },
  created: function () {
    document.title = "Add News"
    this.$stat.log({ page: 'publication add', action: 'open publication add' })
    this.permission = ac.can(this.user.acgroups).createAny('publication').granted
    this.countryOptions[0] = { value: null, text: 'Select country' }
    for (let i = 0, len = countries.length; i < len; i++) {
      this.countryOptions[i + 1] = {}
      this.countryOptions[i + 1].value = countries[i].alpha2
      this.countryOptions[i + 1].text = countries[i].country
    }
  },
  data () {
    return {
      countryOptions: [],
      firststep: true,
      finalstep: false,
      permission: false,
      previewstep: false,
      saving: false,
      rendered: {
        comments: null
      },
      item: {
        countryCode: null,
        countryName: null,
        name: null,
        body: null,
        link: null,
        date: moment().format('YYYY-MM-DD'),
        source: null,
        type: 'manual',
        publisher: null,
        langName: 'English',
        langCode: 'en'
      }
    }
  },
  methods: {
    remove: async function () {
      try {
        let apiName = 'cosmos'
        let path = `/publication/${this.item.id}`
        await this.$Amplify.API.del(apiName, path)
        this.item = {
          countryCode: null,
          countryName: null,
          name: null,
          body: null,
          link: null,
          date: moment().format('YYYY-MM-DD'),
          source: null,
          type: 'manual',
          publisher: null,
          langName: 'English',
          langCode: 'en'
        }
        this.firststep = true
        this.previewstep = false
        this.finalstep = false
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
    },
    edit: function () {
      this.firststep = true
      this.previewstep = false
      this.finalstep = false
    },
    preview: function () {
      this.item.countryName = _.find(countries, { alpha2: this.item.countryCode }).country
      this.item.source = this.item.publisher + ' - ' + this.item.type
      this.firststep = false
      this.finalstep = false
      this.previewstep = true
    },
    save: async function () {
      this.$logger.debug(`save start`)
      this.previewstep = false
      this.saving = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/news/publication`
        let params = { body: this.item }
        let response = await this.$Amplify.API.put(apiName, path, params)
        this.$stat.log({ page: 'publication add', action: 'saved new publication' })
        this.$logger.debug('saved OK:', response)
        this.item = response
        this.finalstep = true
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.saving = false
    }
  }
}
</script>

<style></style>
